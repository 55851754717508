

.critere-container{
    max-width: 90%;
    /*min-height: 90vh;*/
    position: relative;
    margin: auto;
}




.title-container{
    margin-top: 8%;
    margin-bottom: 3%;
}


h1{
    color: #c16777;
    text-align: center;
    font-family: 'SpaceGrotesk-Bold';
    font-size: 1.8rem;
    margin-bottom: 7%;
}

.all-card-container{
    display: flex;
    /* justify-content: space-evenly;   */
}


.card-container{
  /*height: 60vh;*/
  /* left: 2%; */
  margin-top: 3%;
  cursor: pointer;
  width: 90%;
  margin: auto;
}

.columns{
    
}

.card-container:hover{
    box-shadow: 10px 10px 50px 10px rgba(126, 126, 126, 0.431);
    transition:all 0.2s;
    transform: scale(1.1)
}



.img-container{
    width: 75%;
    margin: auto;
    /*height: 35vh;*/
    text-align: center;
    margin-top: 5%;

}

#image{
    max-width: 100%;
    max-height: 100%;
}
#vid-cou{
    max-width: 76%;
    max-height: 70%;
}

.text-container{
    left: 18%;
    width: 65%;
    text-align: center;
    margin: auto;
    margin-top: 10%;
    font-family: 'SpaceGrotesk';
    font-size: 1rem;
    color: white;
    overflow: hidden;
}

.video{
    
}


@media screen and (max-width: 1025px) {
    h1{
        font-size: 1.2rem;
        margin-bottom: 15%;
    }



    .text-container{
        font-size: 0.6rem;
    }

    
}


@media screen and (min-width: 1500px) {
    .critere-container{
        margin-top: 20%;
        
        
    }

    .card-container{
        width: 70%;
        margin: auto;
        
    }

    .text-container{
        font-size: 1rem;
    }

    
}


@media screen and (min-width: 2000px) {

    .critere-container{
        margin-top: 40%;
    }


    .card-container{
        width: 70%;
        margin: auto;
        
    }

    .text-container{
        font-size: 1rem;
    }

  
       
    

}