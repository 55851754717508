@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,400;1,300&display=swap');




.footer-container{
    height: 25vh;
    background-color: black;
    display: block;
    border-radius: 2px;
    margin-top: 5%;
    
}

.social-footer{
    width: 50%;
    margin: auto;
    margin-bottom: 2%;
    display: flex;
    justify-content: space-evenly;
    font-size: 4em;
}


.footer-text{
    text-align: center;
    color: white;
    font-family: 'Exo','sans-sherif';
    font-size: 1em;
    margin-top: 0.2%;
    
    
}

.termConditions-text{
    text-align: center
}

.termConditions-text a {
    text-decoration: none;
    color: #c16777;
    font-family: 'Exo','sans-sherif';
    font-size: 0.6rem;
}


@media screen and (max-width: 850px) {
    .social-footer{
        font-size: 2rem;
    }

    .footer-text{
        font-size: 1.2rem;
        margin-top: 8%;
    }
}




