


.timeline-container{
    max-width : 100%;
    position: relative;
    margin-bottom: 8%;

}




.timeline-header{
    color: #c16777;
    font-size: 1.6rem;
    text-align: center;
}

#katana_canvas{
    max-width: 50%; 
}



.image-timeline-container{
    max-width: 100%;
    max-height: 50%;
    position: relative;
    /* margin-bottom: 10%; */
    
   
   
}

.video-katana-container{
    position: relative;
    max-width:50vw;
    min-width: 50vw; 
    margin: auto;
}


#katana_canvas{
   position: relative;
   max-width:60vw;
   min-width: 60vw;   
}

@media screen and (max-width: 800px) {
    #katana_canvas{
       /*max-width:105vw;
        max-width:130vw;*/
        margin-bottom: 5%;
        max-width:50vw;
        min-width: 50vw; 
        
    }

    

    .timeline-header{
        font-size: 1.2rem;
        
    }


    .video-katana-container{
        margin-bottom: 20%;
        max-width:80vw;
        min-width: 80vw; 
    }

    .image-timeline-container{
        margin-bottom: 20%;
    }

}


@media screen and (min-width: 1500px) {
    .video-katana-container{
        /* margin-bottom: 20%; */
        max-width:43vw;
        min-width: 43vw; 
    }
    #katana_canvas{
        /*max-width:105vw;
         max-width:130vw;*/
         margin-bottom: 5%;
         max-width:50vw;
         min-width: 50vw; 
         
     }

    

}







